export default {
  components: {
    FormField: () => import(/* webpackChunkName: "formField" */ '@/components/FormField'),
  },
  data: function () {
    return {
      inputTimeout: null,
      retry: 3,
      validateOnBlur: true,
    }
  },
  props: {
    field: {
      required: true,
      type: Object,
    },
  },
  mounted: function () {
    this.field.props.rules.push(() => this.field.error ? this.field.error : true)
    this.inputTimeout = setInterval(this.inputReady, 500)
  },
  methods: {
    inputReady () {
      const inputs = this.getInput()
      if (inputs.length === 1) {
        clearInterval(this.inputTimeout)

        this.$set(this.field, 'validate', inputs[0].validate)
        this.$set(this.field, 'reset', inputs[0].reset)
      } else if (inputs.length > 1) {
        clearInterval(this.inputTimeout)
      } else {
        if (this.retry-- === 0) {
          clearInterval(this.inputTimeout)
        }
      }
    },
    getInput: function () {
      const results = []
      const search = (children, depth = 0) => {
        for (const child of children) {
          if (child.errorBucket !== undefined) {
            results.push(child)
          } else {
            search(child.$children, depth + 1)
          }
        }
        if (depth === 0) return results
      }

      return search(this.$children)
    },
    validate: function (error = null) {
      this.field.error = error
      if (error) {
        this.field.validate(true)
        this.validateOnBlur = false
      } else {
        this.field.validate()
        this.validateOnBlur = true
      }
    },
  },
}
